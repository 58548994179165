import React from 'react';
import NumericInput from 'react-numeric-input';

const InputNumber = (props) => {

  return (
      <NumericInput
        mobile={props.mobile}
        className={"h-10" + ' ' + props.className}
        min={1}
        onChange={(value) => props.onChange(value)}
        defaultValue={props.defaultValue || 1}
      />
  )
}

export default InputNumber;
